<template>
  <bumps-location-template
    :comedo-locations="comedoLocations"
    :gender="gender"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import BumpsLocationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/bumps-location/BumpsLocationTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { field, requiredArrayField } = fieldBuilder;

const FIELDS = [field('gender'), requiredArrayField('comedoLocations')];

export default {
  name: 'BumpsLocation',
  components: { BumpsLocationTemplate },
  mixins: [makeStep(FIELDS)]
};
</script>
